<div class='lookback-request'>
  <h1>Lookback</h1>
  <p>
    Look back in time to see if two summoners have played together in the past while also getting a glimpse of their
    past match history. *
  </p>

  <form (ngSubmit)='submitLookbackRequest()'>

    <div class='form-group' *ngFor='let summoner of lookbackRequest.summoners; index as idx;'>
      <div class='summoner-with-tag'>
        <div class='summoner-input-container'>
          <label [for]="'summoner' + (idx + 1) + '_name'">Summoner {{ idx + 1 }}</label>
          <input type='text'
                 class='form-control'
                 [id]="'summoner' + (idx + 1) + '_name'"
                 [name]="'summoner' + (idx + 1) + '_name'"
                 [placeholder]="'Summoner ' + (idx + 1)"
                 [(ngModel)]='summoner.name'
                 (change)='handleInputChangeForSummoner(summoner)' />
        </div>

        <div class='tag-input-container'>
          <label [for]="'summoner' + (idx + 1) + '_tag'">Tag</label>
          <input type='text'
                 class='form-control'
                 [id]="'summoner' + (idx + 1) + '_tag'"
                 [name]="'summoner' + (idx + 1) + '_tag'"
                 placeholder='Tag'
                 [(ngModel)]='summoner.tag'
                 (change)='handleInputChangeForTag(summoner)' />
        </div>

        @if (lookbackRequest.summoners.length > 2) {
          <span (click)='removeSummoner(idx)' class='remove-summoner'>&times;</span>
        }
      </div>
    </div>

    @if (this.lookbackRequest.summoners.length < 5) {
      <div class='form-group'>
        <span (click)='addSummoner()' class='add-summoner'>&plus; Add summoner</span>
      </div>
    }

    <div class='form-group'>
      <label for='region'>Region</label>

      <ng-select [items]='availableRegions'
                 [ngModel]='lookbackRequest.platform'
                 (ngModelChange)='this.onPlatformChange($event)'
                 [multiple]='false'
                 [appendTo]="'body'"
                 bindLabel='displayName'
                 bindValue='id'
                 id='region'
                 name='region' />
    </div>

    <div class='form-group'>
      <label for='queueType'>Queue Type</label>

      <ng-select [items]='availableQueueTypes'
                 [(ngModel)]='lookbackRequest.queueTypes'
                 [multiple]='true'
                 [appendTo]="'body'"
                 [closeOnSelect]='false'
                 bindLabel='displayName'
                 bindValue='id'
                 id='queueType'
                 name='queueType' />
    </div>
    <button type='submit' class='btn btn-default'>Submit</button>
  </form>

  <p class='note'>
    * Due to Riot API limitations, we can only look back up until 1000 games or 2 years back. <br />
    However, once a summoner has been looked up once, we will attempt to regularly fetch their match history ids.
  </p>

  <!--  <ng-adsense style="display: block; margin-top: 25px"-->
  <!--              [adSlot]="1114593273"-->
  <!--              [fullWidthResponsive]="true"-->
  <!--              display="block"-->
  <!--              adFormat="auto"/>-->
</div>
